/* quicksand-300normal - all */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: 
    url('./files/quicksand-all-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/quicksand-all-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-400normal - all */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: 
    url('./files/quicksand-all-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/quicksand-all-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-500normal - all */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: 
    url('./files/quicksand-all-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/quicksand-all-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-600normal - all */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: 
    url('./files/quicksand-all-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/quicksand-all-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-700normal - all */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: 
    url('./files/quicksand-all-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/quicksand-all-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
